import { skipToken } from "@reduxjs/toolkit/query/react";
import { ReactNode } from "react";
import styled from "styled-components";

import { ConfluencePageView } from "features/documentation/DocumentationWindow/ConfluencePageView/ConfluencePageView";
import { DocumentationWindowContentError } from "features/documentation/DocumentationWindow/DocumentationWindowContentError";
import { DocumentationWindowContentLoading } from "features/documentation/DocumentationWindow/DocumentationWindowContentLoading";
import { DocumentationWindowHeader } from "features/documentation/DocumentationWindow/DocumentationWindowHeader";
import { useCloseDocumenationOnNavigate } from "features/documentation/hooks/useCloseDocumenationOnNavigate";
import { useAppDispatch, useAppSelector } from "store";
import { useGetConfluencePageQuery } from "store/services/confluence.service";
import { clearConfluencePageId } from "store/slices/documentation.slice";

const DocumentationWindowStyled = styled.div`
    position: absolute;
    bottom: 1rem;
    right: 3rem;

    width: 40rem;
    max-height: 80vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    background-color: ${({ theme }) => theme.color.white};
    border: none;
    border-radius: 0.5rem;
    z-index: 20;
    box-shadow: 0 0 0.25rem #c30a1466;
`;

const DocumentationWindowContent = styled.div`
    padding: 0 1.5rem 1rem 1.5rem;
    overflow: auto;
`;

const DocumentationWindow = () => {
    const confluencePageId = useAppSelector((state) => state.documentation.confluencePageId);

    const { data, isFetching, isLoading } = useGetConfluencePageQuery(
        confluencePageId ? { pageId: confluencePageId } : skipToken
    );

    const dispatch = useAppDispatch();
    const handleClose = () => {
        dispatch(clearConfluencePageId());
    };

    useCloseDocumenationOnNavigate();

    if (!confluencePageId) return null;

    let content: ReactNode = null;
    if (isFetching || isLoading) {
        content = <DocumentationWindowContentLoading />;
    } else if (!data) {
        content = <DocumentationWindowContentError confluencePageId={confluencePageId} />;
    } else {
        content = <ConfluencePageView data={data} />;
    }

    return (
        <DocumentationWindowStyled>
            <DocumentationWindowHeader handleClose={handleClose} />
            <DocumentationWindowContent>{content}</DocumentationWindowContent>
        </DocumentationWindowStyled>
    );
};

export { DocumentationWindow };
